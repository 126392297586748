/*================================================================================
	Item Name: AcademyLens - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.-ellipsis-1 {
	overflow: hidden;
	text-overflow: -o-ellipsis-lastline;
	// text-overflow: ellipsis;
	/* display: block; */
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	max-height: 1.412;
}

.-ellipsis-2 {
	overflow: hidden;
	text-overflow: -o-ellipsis-lastline;
	// text-overflow: ellipsis;
	/* display: block; */
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	max-height: 2.824em;
}

.-ellipsis-3 {
	overflow: hidden;
	// text-overflow: -o-ellipsis-lastline;
	text-overflow: ellipsis;
	/* display: block; */
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	max-height: 4.236em;
}

.tag {
	background-color: aliceblue;
	display: inline-block;
	padding: 7px;
	border-radius: 10px;
	font-size: smaller;
}

.tag + .tag {
	margin-left: 5px;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.grid {
	display: grid;
}

.c-card {
	margin-bottom: 2rem;
	box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
	background-color: #fff;
	background-clip: border-box;
	border: 0 solid rgba(34, 41, 47, 0.125);
	border-radius: 0.428rem;
	transition: all 0.3s ease-in-out, background 0s, color 0s, border-color 0s;
}

.gap-1	 { gap: 0.20rem; }
.gap-2	 { gap: 0.5rem; }
.gap-3	 { gap: 0.75rem; }
.gap-4	 { gap: 1rem; }


.grid-cols-1	{ grid-template-columns: repeat(1, minmax(0, 1fr)); }
.grid-cols-2	{ grid-template-columns: repeat(2, minmax(0, 1fr)); }
.grid-cols-3	{ grid-template-columns: repeat(3, minmax(0, 1fr)); }
.grid-cols-4	{ grid-template-columns: repeat(4, minmax(0, 1fr)); }
.grid-cols-5	{ grid-template-columns: repeat(5, minmax(0, 1fr)); }
.grid-cols-6	{ grid-template-columns: repeat(6, minmax(0, 1fr)); }
.grid-cols-7	{ grid-template-columns: repeat(7, minmax(0, 1fr)); }
.grid-cols-8	{ grid-template-columns: repeat(8, minmax(0, 1fr)); }
.grid-cols-9	{ grid-template-columns: repeat(9, minmax(0, 1fr)); }
.grid-cols-10	{ grid-template-columns: repeat(10, minmax(0, 1fr)); }
.grid-cols-11	{ grid-template-columns: repeat(11, minmax(0, 1fr)); }
.grid-cols-12	{ grid-template-columns: repeat(12, minmax(0, 1fr)); }
.grid-cols-none	{ grid-template-columns: none; }

.school-grade-tag {
	background-color: rgba(var(--bs-gray-200));

	--bs-text-opacity: 1;
	// color: #b9b9c3;
	color: var(--bs-gradient);
	font-size: smaller;
	// border-radius: 5px;
	padding-inline: 10px;
	border: 0.01px solid var(--bs-gray-500);
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.school-blog-card {
    background-color: #fff;
}

.school-blog-card + .school-blog-card {
	margin-left: 10px;
}

.school-blog-card > img {
	// min-width: 420px;
	// max-width: 420px;
}

.blogs-container {
	display: flex;
}

@media (max-width: 767px) {
	.school-blog-card {
		background-color: aliceblue;
	}
	.blogs-container {
		flex-direction: column;
	}
}